import React, { useState, useEffect, useRef, useContext } from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { getamountPoints,checkOffer } from "../../helpers";
import Colors from "../../constants/Colors";

export default function Offers({
  offers,
  flexy,samanet,
  handelbalnaceselection,
  handelussdcoderequest,
  handelofferselection,
  handleoperationtype,
  operator,
  pointsflexy,
  pointsoffers,
  handelofferselectiondesc,
  NumberOffers
}) {
  const { t } = useTranslation();
  const [selectedbalance, setselectedbalance] = useState(null);
  const [selectedoffer, setselectedoffer] = useState(null);

  const balances = [
    {
      id: 1,
      amount: 100
    },
    {
      id: 2,
      amount: 190
    },
    {
      id: 3,
      amount: 200
    },
    {
      id: 4,
      amount: 500
    },
    {
      id: 5,
      amount: 1000
    },
    {
      id: 6,
      amount: 1500
    }
  ];





  return (
    <>
      <div className="row justify-content-center text-center  flexy-items-holder">
        <span className="text-uppercase mb-4 flexy-subtitle">{t("flexy")}</span>
        <div className="row justify-content-center mb-5 ">
          {balances.map((item, index) => {
            return (
              <div
                className="col-sm-4 col-lg-4 mb-4"
                key={index}
                onClick={() => {
                  if (selectedbalance != item.id) {
                    handleoperationtype("flexy");
                    setselectedbalance(item.id);
                    handelbalnaceselection(item.amount);
                    setselectedoffer(null);
                    handelussdcoderequest(flexy[0].code, item.amount);
                    handelofferselection(null);
                    handelofferselectiondesc(null)
                  } else {
                    setselectedbalance("");
                    handelbalnaceselection("");
                    setselectedoffer(null);
                    handelussdcoderequest(null);
                    handelofferselection(null);
                    handelofferselectiondesc(null)
                  }
                }}
              >
                <div
                  className={
                    selectedbalance == item.id
                      ? "card flexy-item selectedbutton"
                      : "card flexy-item"
                  }
                >
                  <div className="card-body  ">
                    <span
                      className={
                        selectedbalance == item.id
                          ? "flexy-amount white_text"
                          : "flexy-amount"
                      }
                    >
                      {t("flexy")} {item.amount} DA
                    </span>
                    {getamountPoints(item.amount, pointsflexy, "flexy") != 0 && <span className="points">
                      {getamountPoints(item.amount, pointsflexy, "flexy")}
                        <img src={'/images/pts_gift.png'}  className="pts_icon" width={'21'} />
                    </span>}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="row justify-content-center text-center mt-60 flexy-items-holder">
        <span className="text-uppercase mb-4 flexy-subtitle">
          {t("activation")}
        </span>
        <div className="row justify-content-center mb-5">
          {offers.map((item, index) => {
            return (
              <div
                className="col-sm-4 col-lg-4 mb-4"
                key={index}
                onClick={() => {
                  if (selectedoffer != item.id) {
                    handleoperationtype("activation");
                    handelbalnaceselection(item.amount);
                    setselectedbalance(null);
                    setselectedoffer(item.id);
                    handelussdcoderequest(item.code, null);
                    handelofferselection(item);
                    handelofferselectiondesc(item.amount);
                  } else {
                    handleoperationtype("flexy");
                    handelbalnaceselection("");
                    setselectedbalance(null);
                    setselectedoffer(null);
                    handelussdcoderequest(null);
                    handelofferselection(null);
                    handelofferselectiondesc(null)
                  }
                }}
              >
                <div
                  className={
                    selectedoffer == item.id
                      ? "card flexy-item selectedbutton"
                      : "card flexy-item"
                  }
                >
                  <div className="card-body  ">
                    <span
                      className={
                        selectedoffer == item.id
                          ? "flexy-amount white_text"
                          : "flexy-amount"
                      }
                    >
                      {checkOffer(item.amount, NumberOffers) != null ? checkOffer(item.amount, NumberOffers) : item.title}
                    </span>
                    <span className="points">
                      {getamountPoints(item.amount, pointsoffers, "offre")}
                      {/*<CardGiftcardIcon className="mx-1" />*/}
                        <img src={'/images/pts_gift.png'}  className="pts_icon" width={'21'} />
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="row justify-content-center text-center mt-60 flexy-items-holder">
      {samanet.length > 0 && ( <span className="text-uppercase mb-4 flexy-subtitle">
          SAMA NET
        </span>)} 
        <div className="row justify-content-center mb-5">
          {samanet.map((item, index) => {
            return (
              <div
                className="col-sm-4 col-lg-4 mb-4"
                key={index}
                onClick={() => {
                  if (selectedoffer != item.id) {
                    handleoperationtype('samanet');
                    handelbalnaceselection(item.amount);
                    setselectedbalance(null);
                    setselectedoffer(item.id);
                    handelussdcoderequest(item.code, null);
                    handelofferselection(item);
                     handelofferselectiondesc(null)
                  } else {
                    handleoperationtype('flexy');
                    handelbalnaceselection('');
                    setselectedbalance(null);
                    setselectedoffer(null);
                    handelussdcoderequest(null);
                    handelofferselection(null);
                     handelofferselectiondesc(null)
                  }
                }}
              >
                <div
                  className={
                    selectedoffer == item.id
                      ? "card flexy-item selectedbutton"
                      : "card flexy-item"
                  }
                >
                  <div className="card-body  ">
                    <span
                      className={
                        selectedoffer == item.id
                          ? "flexy-amount white_text"
                          : "flexy-amount"
                      }
                    >
                      {item.title}
                    </span>
                    <span className="points">
                      {getamountPoints(item.amount, pointsoffers, "offre")}
                      {/*<CardGiftcardIcon className="mx-1" />*/}
                      <img src={'/images/pts_gift.png'}  className="pts_icon" width={'21'} />
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
