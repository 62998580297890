
export function formatnetworkCode(code) {
  let num = "" + code;
  num = "0" + num;
  return num;
}


export function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

export function nFormatterk(num) {
 
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  
}

export function editUssdforResponse(ussd, value, operator) {
  let selected = "";
  let new_ussd = null;
  let request = null;
  if (operator == 1) {
    selected = value.substring(0, value.indexOf("."));
    new_ussd = ussd.substring(0, 22);
    request = new_ussd + "*" + selected + "#";
  } else if (operator == 2) {
    selected = value.substring(0, value.indexOf(":"));
    new_ussd = ussd.substring(0, 22);
    request = new_ussd + "and" + selected;
  } else {
    selected = value.substring(0, value.indexOf("-"));
    new_ussd = ussd.substring(0, 15);
    request = new_ussd + "*" + selected + "*0000#";
  }

  return request;
}

export function removeOfferFromResponse(value) {
  var list = [
    "Offres Internet",
    "Suite",
    "Internet 1000DA=15Go /30 jours",
    "IMTYAZ",
    "Internet",
    "8:",
    "Internet 1000DA=",
    "Kaspersky",
    "Babour ellou7 Yara Vod",
    "Storm",
    "Plus","Choisir","Cher Client"
  ];

  let check = false;
  let item = value;
  for (let i = 0; i < list.length; i++) {
    check = item.includes(list[i]);
    if (check) {
      item = null;
      break;
    }
  }

  return item;
}

export function getAmountFromSelectedChoise(value, operator) {
  var list = [
    "50",
    "100",
    "200",
    "300",
    "400",
    "500",
    "600",
    "700",
    "750",
    "800",
    "1000",
    "1200",
    "1500",
    "2000","2500",
    "3000","3500","5000"
  ];
  const matches = value.match(/\d+/g);

  let amount = "0";
  for (let i = 0; i < list.length; i++) {
    for (let ii = 0; ii < matches.length; ii++) {
      if (parseInt(matches[ii]) === parseInt(list[i])) {
        amount = matches[ii];
        break;
      }
    }
  }

  return amount;
}
 
export function getamountPoints(amount, points, type) {
  var pts = 0;
  if (type == "flexy") {
    
      var result = points.find(
        item => parseInt(item.amount) <= parseInt(amount)
      );
   
  } else {
    var result = points.find(
      item => parseInt(item.amount) === parseInt(amount)
    );
  }

  if (result != null) {
    pts = result.pts;
  }

  return pts;
}

export function cardCodeFormat(code) {
  let newcode = code.match(/.{1,4}/g);

  return newcode.join(" ");
}

export function CalculateToPay(array,activebalance) {
  var sum = 0;

   array.forEach(e => {
        sum += parseInt(e.unpaid)
    })
   var result = parseInt(sum) - parseInt(activebalance)
  return result;
}

export function CalculateCartTotal(data,itemqty) {
  var sum = 0;
  
   data.forEach((el,index )=> {
    if (el.offers == null) {
      sum += parseFloat(el.price) * parseInt(itemqty[index].qty)
    } else {
      sum += parseFloat(el.offers) * parseInt(itemqty[index].qty)
    }
        
    })

  return sum;
}

export function checkIdIfExsist  (id,lateList) {
 
      const found =  lateList.some(el => {
        
        if (el.client_id == id) {
          return true
        }
      })
    return found
}

export function checkOffer  (amount,NumberOffers) {
var offer = null;

if(NumberOffers != null){
 NumberOffers.simoffer.some(el => {
        if (parseInt(el.amount) == parseInt(amount)) {
          offer = NumberOffers.category + ' ' +el.amount
         
        }
      })
    }

  return offer  
}


export function calculateTotalPaymentsToday(data) {
  const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format

  return data.reduce((total, item) => {
    if (item.created_at) { // Check if the date property exists
      const itemDate = new Date(item.created_at).toISOString().split('T')[0]; // Convert item date to 'YYYY-MM-DD' format
      
      if (itemDate === today) {
        total += parseFloat(item.amount) || 0; // Parse amount as a float and ensure it defaults to 0 if parsing fails
      }
    }
    return total;
  }, 0);
}

export function getOperator(number) {
  const firstDigit = number.toString().charAt(0);
  
  if (firstDigit === '6') {
    return 1; // mobilis
  } else if (firstDigit === '7') {
    return 2; // djezzy
  } else {
    return 3;// ooredoo
  }
}




