import React, { useState, useEffect, useRef, useContext } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TWS } from "../../services/WebServices";
import SendLoading from "../flexy/SendLoading";
import Toolbar from '@mui/material/Toolbar';
import Checkbox from '@mui/material/Checkbox';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Chip from '@mui/material/Chip';
import {calculateTotalPaymentsToday} from '../../helpers'
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import Divider from '@mui/material/Divider';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



export default function Orders({userid, usertoken}) {
    const [OrdersList, setOrdersList] = useState([]);
    const [loading, setloading] = useState(true);
 


  useEffect(() => {

    Orders()

    }, []);

function Orders(){
    TWS.getRespOrders(userid, usertoken)
      .then(res => {
        setOrdersList(res.data);
        
        setloading(false)

      })
      .catch(() => {});
}





const [checkedRows, setCheckedRows] = useState({});
const [totalSum, setTotalSum] = useState(0);
 // Handle checkbox change
 const handleChange = (event, id, amount) => {
  const isChecked = event.target.checked;

  // Update checked rows
  setCheckedRows((prevChecked) => ({
    ...prevChecked,
    [id]: isChecked,
  }));

  // Update total sum based on checked status
  setTotalSum((prevSum) => isChecked ? parseInt(prevSum) + parseInt(amount) : parseInt(prevSum) - parseInt(amount));
};

function setaspaid(orderid){
  setloading(true)
  TWS.setOrderAsPaid(orderid, usertoken)
      .then(res => {
       if (res == 'success') {
        Orders()
       }
        setloading(false)

      })
      .catch(() => {});
}

/** show order */
const [OpenOrder, setOpenOrder] = useState(false);
const [selectedOrder, setselectedOrder] = useState([]);

const handelOrderModal = () => {
  setOpenOrder(!OpenOrder);
};

function showOrder(order){
  setOpenOrder(!OpenOrder);
  setselectedOrder(order)
}



  return (
    <>
    
    <Typography className="todaytotalp" variant="body1"  component="div">
          Total : {totalSum == 0  ? calculateTotalPaymentsToday(OrdersList) : totalSum} DA
    </Typography>

    
 
 

    <Toolbar
     className="tableTitle"
    >
    <Typography  className="table_p_title"  component="div">
          Votre commandes 
        </Typography>

    
    </Toolbar>
    {OrdersList.length > 0 ? (<TableContainer className="pro_tables" component={Paper} >
       
      <SendLoading loading={loading} />
      <Table sx={{ minWidth: 650 }} aria-label="client table">
        <TableHead>
          <TableRow>
          <TableCell>Sélectionner</TableCell>
            <TableCell>Client</TableCell>
            <TableCell align="center">Région</TableCell>
            <TableCell align="center">Montant&nbsp;(DA)</TableCell>
            <TableCell align="center">Payé</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Opération</TableCell>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {OrdersList.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              
            >
               <TableCell >
               <Checkbox
                    checked={checkedRows[row.id] || false}
                    onChange={(event) => handleChange(event, row.id, row.amount)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    sx={{
                      color: '#6610f2',
                      '&.Mui-checked': {
                        color: '#6610f2',
                      },
                    }}
                  />
                </TableCell>
              <TableCell component="th" scope="row">
                {row.client.name}
              </TableCell>
          
            <TableCell align="center">{row.client.region}</TableCell>
              
              <TableCell align="center">{row.amount}</TableCell>
              <TableCell align="center"><Chip size="small" color="success" label={row.paid ? 'OUI' : 'NON'} style={{backgroundColor: row.paid ? 'green':'red'}} /></TableCell>
                <TableCell align="center"><Chip size="small" color="success" label={row.created_at} style={{backgroundColor: '#f1F1F1', color:'#000'}} /></TableCell>
                <TableCell align="center">
                  
                <ButtonGroup
                  disableElevation
                 variant="text"
                 
                >
                   <IconButton  size="small" color="success" sx={{ marginRight:0.5}} onClick={()=>{
                    showOrder(row.storeorder_items)
                  }}><RemoveRedEyeIcon/>
                  </IconButton >
                  <IconButton  size="small" color="success" sx={{ marginRight:0.5}} onClick={()=>{
                    setaspaid(row.id)
                  }}><DoneAllIcon/>
                  </IconButton >
                  </ButtonGroup>
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>):(
      <Typography className="text-center mb-4 mt-4" variant="body3"  component="div">
       Vous n'avez aucune commande.
      </Typography>
    )}


     {/**Order */}
          <Dialog
            open={OpenOrder}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth={'sm'}
            onClose={handelOrderModal}
            aria-describedby="alert-dialog-slide-description"
            sx={{
              padding:0,
              overflow:'hidden'
            }}
          >

        <DialogTitle sx={{fontSize:16, textTransform:'capitalize'}}>Produits</DialogTitle>
        <DialogContent sx={{ padding:0 }}>
         
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        
        
          {selectedOrder.map((item) => (
            <>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Avatar
                  alt={item.product.name}
                  src={'https://mdash.rminov.com/'+item.product.images}
                  sx={{ width: 56, height: 56, marginRight:4 }}
                />
            </ListItemAvatar>
            <ListItemText
              primary={item.product.name} 
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ color: 'text.primary', display: 'inline' }}
                  >
                    {item.product.price +' DZD'}
                  </Typography>
                  {item.product.offers != null && " — Offre : "+item.product.offers +' DZD'}
                </React.Fragment>
              }
            />
          </ListItem>
        <Divider variant="inset" component="li" />
        </>
        ))}
          
        </List>
          
        </DialogContent>
        <DialogActions sx={{ padding:1 ,justifyContent:'center'}}>
         
          <Button  variant="contained" onClick={handelOrderModal}  className="annuler_btn">Fermer</Button>
        </DialogActions>

          </Dialog>
   
    </>
  );
}