import React, { useState, useEffect, useCallback } from "react";
import CustomerRecharge from "./CustomerRecharge";
import CustomerRechargeSim from "./CustomerRechargeSim";

import ClientList from "./ClientList";
import AddClient from "./AddClient";
import Payments from "./Payments";

import Orders from "./Orders";
import Statistics from "./Statistics";
import { TWS } from "../../services/WebServices";
import SendLoading from "../flexy/SendLoading";
import Loading from "../Loading";
import { TextField,  BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { AddReaction, AccountBalanceWallet, ListAlt, AddTask, QueryStats } from '@mui/icons-material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';


export default function ProAccount({ user }) {
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [clientListRecharge, setClientListRecharge] = useState([]);
  const [total, setTotal] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const isMobile = window.innerWidth < 600;
  const handleCategoryChange = (index) => {
    setCategoryIndex(index);
    if (index === 0) getClientsRecharges();
  };

  const getClientsRecharges = useCallback(() => {
    setLoadingData(true);
    TWS.rechargeListProAccount(user.id, user.token)
      .then(res => {
        setClientListRecharge(res.data);
        const totalUnpaid = res.data.reduce((sum, client) => sum + parseInt(client.unpaid), 0);
        setTotal(totalUnpaid);
        setLoadingData(false);
      })
      .catch(() => setLoadingData(false));
  }, [user.id, user.token]);

  useEffect(() => {
    getClientsRecharges();
  }, [getClientsRecharges]);

  const searchClient = (val) => {
    if (!val) return getClientsRecharges();
    const query = val.toLowerCase();
    const filteredList = clientListRecharge.filter(({ client }) =>
      client.name.toLowerCase().startsWith(query)
    );
    setClientListRecharge(filteredList);
    
  };


  const [simtransactions, setsimtransactions] = useState([]);

  useEffect(() => {
 
    TWS.getTransactionsSim(user.id, user.token)
      .then(res => {
        setsimtransactions(res.data)
 
      })
      .catch(() => {});

}, []);

  if (user.category !== 1) return <Loading />;

  return (
    <div className="container text-center px-8">
      <div className="container-md text-center menu_pro mt-4">
        <div className="btn-group categorybtnList" role="group">
          {["Recharges", "Clients", "Paiements","Commandes", "Ajout", "Statistiques"].map((label, index) => (
            <button
              key={index}
              type="button"
              className={`btn btn-category ${categoryIndex === index ? "btn-cat-selected" : ""}`}
              onClick={() => handleCategoryChange(index)}
            >
              {label}
            </button>
          ))}
        </div>
      </div>

      {categoryIndex === 0 && (
        <div className="row">
          <SendLoading loading={loadingData} />
          <div className="row my-4 d-flex justify-content-md-end searchbyname rechargelist">
            <div className="col-sm-10 col-md-6">
              <TextField
                size="small"
                id="searchproaccount"
                label="Chercher par nom"
                type="text"
                sx={{ width: '74%', background: '#fff' }}
                InputLabelProps={{ shrink: true }}
                onChange={e => searchClient(e.target.value)}
              />
            </div>

          </div>
          
          {simtransactions.map((row, index) => (
            <CustomerRechargeSim key={index} data={row} responsable={user.name} />
          ))}
          
          {clientListRecharge.map((row, index) => (
            <CustomerRecharge key={index} data={row} />
          ))}
        </div>
      )}

      {categoryIndex === 1 && <ClientList userid={user.id} usertoken={user.token} />}
      {categoryIndex === 2 && <Payments userid={user.id} usertoken={user.token} />}
      {categoryIndex === 3 && <Orders userid={user.id} usertoken={user.token} />}
      {categoryIndex === 4 && <AddClient token={user.token} userid={user.id} />}
      {categoryIndex === 5 && <Statistics userid={user.id} usertoken={user.token} />}
     

      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex:99, }} elevation={3}>
        <BottomNavigation
          className="navigation_pro"
          sx={{
            paddingTop: 1,
            height: 65,
           
            "& .Mui-selected, .Mui-selected > svg": { color: "#8343f5" },
            "& .MuiBottomNavigationAction-root": {
              minWidth: 0, // Reduce the minimum width of each button
              padding: "6px 8px", // Add some padding
            },
            "& .MuiBottomNavigationAction-label": {
              fontSize: "0.7rem", // Reduce label font size for better fit
            },
          }}
          showLabels={!isMobile}
          value={categoryIndex}
          onChange={(event, newValue) => handleCategoryChange(newValue)}
        >
          <BottomNavigationAction label="Recharges" icon={<AddTask />} />
          <BottomNavigationAction label="Clients" icon={<ListAlt />} />
          <BottomNavigationAction label="Paiements" icon={<AccountBalanceWallet />} />
          <BottomNavigationAction label="Commandes" icon={<LocalShippingIcon />} />
          <BottomNavigationAction label="Ajouter" icon={<AddReaction />} />
          <BottomNavigationAction label="Stats" icon={<QueryStats />} />
        </BottomNavigation>
      </Paper>
      
    </div>
  );
}
